import qs from 'qs'

const fields = [
  'title',
  'slug',

  'dateStart',
  'dateEnd',
  'hideDates',

  'editionIndex',
  'country',
  'city',
  'titleAnnotation',

  'locale'
]

const populate = {
  colors: true
}

export const queryBiennialEntriesThumbnailBasic = (options: queryOptions) => {
  const query = qs.stringify(
    {
      locale: options?.locale || 'en-US',
      sort: options?.sort || ['editionIndex:desc'],
      pagination: {
        page: options?.page || 1,
        pageSize: options?.pageSize || 100
      },
      fields,
      populate: {
        ...populate,
        localizations: {
          fields,
        },
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `biennial-entries?${query}`
}
